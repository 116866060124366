<template>
  <Dialog :commonDialog.sync="VisitDialog">
    <template v-slot:title>
      Reminder
      <v-icon small class="mx-4 color-custom-blue"
        >mdi-checkbox-blank-circle</v-icon
      >
      <template v-if="lodash.isEmpty(visitDetail) === false"
        >Visit# {{ visitDetail.barcode }}</template
      >
      <template v-else>Job# {{ detail.barcode }}</template>
    </template>
    <template v-slot:body>
      <!-- <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow"
        style="max-height: 60vh; position: relative"
      > -->
      <v-form
        ref="timeTrackingForm"
        v-model="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate"
      >
        <v-container class="py-0">
          <v-row>
            <v-col md="12">
              <p class="font-weight-500">Title</p>
              <v-text-field
                v-model="data.title"
                dense
                filled
                color="cyan"
                label="Title"
                :rules="[
                  validateRules.required(data.title, 'Title'),
                  validateRules.maxLength(data.title, 'Title', 100),
                ]"
                solo
                flat
                hide-details
              ></v-text-field>
            </v-col>
            <v-col md="6">
              <p class="font-weight-500">Start Date</p>
              <v-menu
                v-model="datePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    filled
                    flat
                    label="Date"
                    hide-details
                    solo
                    :rules="[validateRules.required(data.date, 'Date')]"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :value="formattedDate"
                    color="cyan"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="data.date"
                  color="cyan"
                  v-on:input="datePicker = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col md="6">
              <p class="font-weight-500">Start Time</p>
              <v-menu
                ref="rawStartTime"
                v-model="startTimePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="rawStartTime"
                transition="scale-transition"
                offset-y
                color="cyan"
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    filled
                    flat
                    label="Start Time"
                    hide-details
                    solo
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    v-model="data.start_at"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    color="cyan"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="startTimePicker"
                  v-model="rawStartTime"
                  full-width
                  ampm-in-title
                  color="cyan"
                  v-on:click:minute="updateStartTime(rawStartTime)"
                  v-on:update:period="updateStartTimeAMPM"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col md="6">
              <p class="font-weight-500">End Date</p>
              <v-menu
                v-model="endDatePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    filled
                    flat
                    label="End Date"
                    hide-details
                    solo
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :value="formattedEndDate"
                    color="cyan"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="data.end_date"
                  color="cyan"
                  :min="data.date"
                  v-on:input="endDatePicker = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col md="6">
              <p class="font-weight-500">End Time</p>
              <v-menu
                ref="rawEndTime"
                v-model="endTimePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="rawEndTime"
                transition="scale-transition"
                offset-y
                color="cyan"
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    filled
                    flat
                    label="End Time"
                    hide-details
                    solo
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    v-model="data.end_at"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    color="cyan"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="endTimePicker"
                  v-model="rawEndTime"
                  full-width
                  ampm-in-title
                  color="cyan"
                  v-on:click:minute="updateEndTime(rawEndTime)"
                  v-on:update:period="updateEndTimeAMPM"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col md="12">
              <p class="font-weight-500">Instructions</p>
              <v-textarea
                v-model="data.instruction"
                auto-grow
                dense
                filled
                flat
                label="Instructions"
                solo
                color="cyan"
                hide-details
                row-height="20"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <!-- </perfect-scrollbar> -->
    </template>
    <template v-slot:action>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-grey-border custom-bold-button white--text"
        color="cyan"
        v-on:click="updateOrCreate"
      >
        Save
      </v-btn>
      <v-btn
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="$emit('close-dialog', true)"
      >
        Close
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import CreateVisitReminderMixin from "@/core/lib/job-visit/create.visit.reminder.mixin";
export default {
  mixins: [CreateVisitReminderMixin],
};
</script>
