var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{attrs:{"commonDialog":_vm.VisitDialog},on:{"update:commonDialog":function($event){_vm.VisitDialog=$event},"update:common-dialog":function($event){_vm.VisitDialog=$event}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Reminder "),_c('v-icon',{staticClass:"mx-4 color-custom-blue",attrs:{"small":""}},[_vm._v("mdi-checkbox-blank-circle")]),(_vm.lodash.isEmpty(_vm.visitDetail) === false)?[_vm._v("Visit# "+_vm._s(_vm.visitDetail.barcode))]:[_vm._v("Job# "+_vm._s(_vm.detail.barcode))]]},proxy:true},{key:"body",fn:function(){return [_c('v-form',{ref:"timeTrackingForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.updateOrCreate.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-container',{staticClass:"py-0"},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('p',{staticClass:"font-weight-500"},[_vm._v("Title")]),_c('v-text-field',{attrs:{"dense":"","filled":"","color":"cyan","label":"Title","rules":[
                _vm.validateRules.required(_vm.data.title, 'Title'),
                _vm.validateRules.maxLength(_vm.data.title, 'Title', 100) ],"solo":"","flat":"","hide-details":""},model:{value:(_vm.data.title),callback:function ($$v) {_vm.$set(_vm.data, "title", $$v)},expression:"data.title"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('p',{staticClass:"font-weight-500"},[_vm._v("Start Date")]),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","filled":"","flat":"","label":"Date","hide-details":"","solo":"","rules":[_vm.validateRules.required(_vm.data.date, 'Date')],"prepend-inner-icon":"mdi-calendar","readonly":"","value":_vm.formattedDate,"color":"cyan"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}},[_c('v-date-picker',{attrs:{"color":"cyan"},on:{"input":function($event){_vm.datePicker = false}},model:{value:(_vm.data.date),callback:function ($$v) {_vm.$set(_vm.data, "date", $$v)},expression:"data.date"}})],1)],1),_c('v-col',{attrs:{"md":"6"}},[_c('p',{staticClass:"font-weight-500"},[_vm._v("Start Time")]),_c('v-menu',{ref:"rawStartTime",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.rawStartTime,"transition":"scale-transition","offset-y":"","color":"cyan","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.rawStartTime=$event},"update:return-value":function($event){_vm.rawStartTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","filled":"","flat":"","label":"Start Time","hide-details":"","solo":"","prepend-inner-icon":"mdi-clock-time-four-outline","readonly":"","color":"cyan"},model:{value:(_vm.data.start_at),callback:function ($$v) {_vm.$set(_vm.data, "start_at", $$v)},expression:"data.start_at"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startTimePicker),callback:function ($$v) {_vm.startTimePicker=$$v},expression:"startTimePicker"}},[(_vm.startTimePicker)?_c('v-time-picker',{attrs:{"full-width":"","ampm-in-title":"","color":"cyan"},on:{"click:minute":function($event){return _vm.updateStartTime(_vm.rawStartTime)},"update:period":_vm.updateStartTimeAMPM},model:{value:(_vm.rawStartTime),callback:function ($$v) {_vm.rawStartTime=$$v},expression:"rawStartTime"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"md":"6"}},[_c('p',{staticClass:"font-weight-500"},[_vm._v("End Date")]),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","filled":"","flat":"","label":"End Date","hide-details":"","solo":"","prepend-inner-icon":"mdi-calendar","readonly":"","value":_vm.formattedEndDate,"color":"cyan"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endDatePicker),callback:function ($$v) {_vm.endDatePicker=$$v},expression:"endDatePicker"}},[_c('v-date-picker',{attrs:{"color":"cyan","min":_vm.data.date},on:{"input":function($event){_vm.endDatePicker = false}},model:{value:(_vm.data.end_date),callback:function ($$v) {_vm.$set(_vm.data, "end_date", $$v)},expression:"data.end_date"}})],1)],1),_c('v-col',{attrs:{"md":"6"}},[_c('p',{staticClass:"font-weight-500"},[_vm._v("End Time")]),_c('v-menu',{ref:"rawEndTime",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.rawEndTime,"transition":"scale-transition","offset-y":"","color":"cyan","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.rawEndTime=$event},"update:return-value":function($event){_vm.rawEndTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","filled":"","flat":"","label":"End Time","hide-details":"","solo":"","prepend-inner-icon":"mdi-clock-time-four-outline","readonly":"","color":"cyan"},model:{value:(_vm.data.end_at),callback:function ($$v) {_vm.$set(_vm.data, "end_at", $$v)},expression:"data.end_at"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endTimePicker),callback:function ($$v) {_vm.endTimePicker=$$v},expression:"endTimePicker"}},[(_vm.endTimePicker)?_c('v-time-picker',{attrs:{"full-width":"","ampm-in-title":"","color":"cyan"},on:{"click:minute":function($event){return _vm.updateEndTime(_vm.rawEndTime)},"update:period":_vm.updateEndTimeAMPM},model:{value:(_vm.rawEndTime),callback:function ($$v) {_vm.rawEndTime=$$v},expression:"rawEndTime"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"md":"12"}},[_c('p',{staticClass:"font-weight-500"},[_vm._v("Instructions")]),_c('v-textarea',{attrs:{"auto-grow":"","dense":"","filled":"","flat":"","label":"Instructions","solo":"","color":"cyan","hide-details":"","row-height":"20"},model:{value:(_vm.data.instruction),callback:function ($$v) {_vm.$set(_vm.data, "instruction", $$v)},expression:"data.instruction"}})],1)],1)],1)],1)]},proxy:true},{key:"action",fn:function(){return [_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button white--text",attrs:{"disabled":!_vm.formValid || _vm.formLoading || _vm.pageLoading,"loading":_vm.formLoading,"color":"cyan"},on:{"click":_vm.updateOrCreate}},[_vm._v(" Save ")]),_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button",attrs:{"disabled":_vm.formLoading || _vm.pageLoading},on:{"click":function($event){return _vm.$emit('close-dialog', true)}}},[_vm._v(" Close ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }