var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-0"},[_c('v-row',{staticClass:"custom-border-grey-dashed"},[_c('v-col',{attrs:{"md":"6"}},[_c('audio',{staticClass:"width-100",attrs:{"id":"audio-player","controls":"","autoplay":"","src":_vm.audioBlob,"type":"audio/mp3","preload":"none"}})]),_c('v-col',{staticClass:"my-auto",attrs:{"md":"6"}},[(_vm.recordingStarted)?[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 custom-grey-border custom-bold-button white--text",attrs:{"color":"cyan"},on:{"click":_vm.stopRecording}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-microphone-off")]),_vm._v(" Stop")],1)]}}],null,false,1743103464)},[_c('span',[_vm._v("Click here to stop recording")])]),_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.recordingPaused)?_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 custom-grey-border custom-bold-button white--text",attrs:{"color":"cyan"},on:{"click":_vm.resumeRecording}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-microphone")]),_vm._v(" Resume")],1):_vm._e()]}}],null,false,1691877646)},[_c('span',[_vm._v("Click here to resume recording")])]),_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 custom-grey-border custom-bold-button white--text",attrs:{"color":"cyan"},on:{"click":_vm.pauseRecording}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-microphone-off")]),_vm._v(" Pause")],1)]}}],null,false,3845933736)},[_c('span',[_vm._v("Click here to pause recording")])]),_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 custom-grey-border custom-bold-button white--text",attrs:{"color":"cyan"},on:{"click":_vm.resetRecording}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-microphone-settings")]),_vm._v(" Reset")],1)]}}],null,false,2460949474)},[_c('span',[_vm._v("Click here to reset recording")])])]:[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 custom-grey-border custom-bold-button white--text",attrs:{"color":"cyan"},on:{"click":_vm.startRecording}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-microphone")]),_vm._v(" Start")],1)]}}])},[_c('span',[_vm._v("Click here to start recording")])])]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }