import * as ics from "ics";
import { saveAs } from "file-saver";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";

export const downloadEvent = ({
  start,
  end,
  title,
  description,
  location,
  url,
  geo,
  categories,
  status,
  organizer,
  attendees,
}) => {
  /*const event = {
    start: [2018, 5, 30, 6, 30],
    end: [2018, 5, 30, 6, 30],
    title: 'Bolder Boulder',
    description: 'Annual 10-kilometer run in Boulder, Colorado',
    location: 'Folsom Field, University of Colorado (finish line)',
    url: 'http://www.bolderboulder.com/',
    geo: { lat: 40.0095, lon: 105.2669 },
    categories: ['10k races', 'Memorial Day Weekend', 'Boulder CO'],
    status: 'CONFIRMED',
    busyStatus: 'BUSY',
    organizer: { name: 'Admin', email: 'Race@BolderBOULDER.com' },
    attendees: [
      { name: 'Adam Gibbons', email: 'adam@example.com', rsvp: true, partstat: 'ACCEPTED', role: 'REQ-PARTICIPANT' },
      { name: 'Brittany Seaton', email: 'brittany@example2.org', dir: 'https://linkedin.com/in/brittanyseaton', role: 'OPT-PARTICIPANT' }
    ]
  }*/

  const event = {
    start,
    end,
    title,
    description,
    location,
    url,
    geo,
    categories,
    status,
    organizer,
    attendees,
    busyStatus: "BUSY",
  };

  ics.createEvent(event, (error, value) => {
    if (error) {
      ErrorEventBus.$emit("update:error", InitializeError(error));
      return false;
    }
    var blob = new Blob([value], { type: "data:text/calendar;charset=utf8" });
    saveAs(blob, title + ".ics");
  });
};
