import moment from "moment-timezone";
import AppConfiguration from "@/core/config/app.config";
import CommonMixin from "@/core/plugins/common-mixin";
import { POST, PUT } from "@/core/services/store/request.module";
import Dialog from "@/view/pages/partials/Dialog.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  props: {
    timeTrackingDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    update: {
      type: Object,
      default() {
        return new Object();
      },
    },
    visit: {
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  watch: {
    visit: {
      deep: true,
      immediate: true,
      handler(param) {
        this.data.visit = this.lodash.toSafeInteger(param.id);
      },
    },
  },
  data() {
    return {
      pageLoading: false,
      datePicker: false,
      rawStartTime: null,
      startTimePicker: false,
      rawEndTime: null,
      endTimePicker: false,
      endDatePicker: false,
      data: {
        visit: 0,
        details: null,
        start_at: null,
        end_at: null,
        date: null,
        end_date: null,
      },
    };
  },
  methods: {
    updateStartTimeAMPM(param) {
      let startTime = this.lodash.toString(this.data.start_at);
      if (startTime) {
        if (param == "am") {
          startTime = this.lodash.replace(startTime, "PM", "AM");
        } else {
          startTime = this.lodash.replace(startTime, "AM", "PM");
        }
        this.updateStartTime(moment(startTime, ["hh:mm A"]).format("HH:mm"));
      }
    },
    updateEndTimeAMPM(param) {
      let endTime = this.lodash.toString(this.data.end_at);
      if (endTime) {
        if (param == "am") {
          endTime = this.lodash.replace(endTime, "PM", "AM");
        } else {
          endTime = this.lodash.replace(endTime, "AM", "PM");
        }
        this.updateEndTime(moment(endTime, ["hh:mm A"]).format("HH:mm"));
      }
    },
    updateStartTime(rawTime) {
      this.$refs.rawStartTime.save(rawTime);
      this.data.start_at = moment(rawTime, ["HH:mm"]).format("hh:mm A");
    },
    updateEndTime(rawTime) {
      this.$refs.rawEndTime.save(rawTime);
      this.data.end_at = moment(rawTime, ["HH:mm"]).format("hh:mm A");
    },
    updateOrCreate() {
      const _this = this;

      if (!_this.$refs.timeTrackingForm.validate()) {
        return false;
      }

      _this.formLoading = true;

      const formData = {
        details: _this.data.details,
        start_at: moment(_this.data.start_at, "hh:mm A").format("HH:mm"),
        end_at: moment(_this.data.end_at, "hh:mm A").format("HH:mm"),
        date: _this.data.date,
        end_date: _this.data.end_date,
        visit: _this.data.visit > 0 ? _this.data.visit : null,
      };

      let requestType = POST;
      let requestURL = "job/" + _this.detail.id + "/time-tracking";

      if (_this.lodash.isEmpty(_this.update) === false) {
        requestType = PUT;
        requestURL =
          "job/" + _this.detail.id + "/time-tracking/" + _this.update.id;
      }

      _this.$store
        .dispatch(requestType, {
          url: requestURL,
          data: formData,
        })
        .then(() => {
          _this.$emit("create:time-tracking", true);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
  },
  computed: {
    formattedDate() {
      let Config = AppConfiguration.get();
      if (Config) {
        return moment(this.data.date).format(Config.dateFormat);
      }
      return this.data.date;
    },
    formattedEndDate() {
      let Config = AppConfiguration.get();
      if (Config) {
        return moment(this.data.end_date).format(Config.dateFormat);
      }
      return this.data.end_date;
    },
    duration() {
      let StartDate = this.lodash.toString(this.data.date);
      if (this.data.start_at) {
        StartDate = this.lodash.toString(StartDate + " " + this.data.start_at);
      }
      let EndDate = this.lodash.toString(this.data.end_date);
      if (this.data.end_at) {
        EndDate = this.lodash.toString(EndDate + " " + this.data.end_at);
      }
      let result = null;

      if (
        StartDate &&
        EndDate &&
        moment(StartDate, ["YYYY-MM-DD hh:mm A"]).isValid() &&
        moment(EndDate, ["YYYY-MM-DD hh:mm A"]).isValid()
      ) {
        StartDate = moment(StartDate, ["YYYY-MM-DD hh:mm A"]);
        EndDate = moment(EndDate, ["YYYY-MM-DD hh:mm A"]);

        let StartTime = moment(StartDate, ["YYYY-MM-DD hh:mm A"]).format(
          "HH:mm"
        );
        let EndTime = moment(EndDate, ["YYYY-MM-DD hh:mm A"]).format("HH:mm");

        if (moment(StartTime, ["HH:mm"]).isAfter(moment(EndTime, ["HH:mm"]))) {
          this.data.end_date = this.lodash.toString(
            moment(this.data.date).add(1, "days").format("YYYY-MM-DD")
          );
          EndDate = moment(EndDate).add(1, "days");
        }

        let duration = moment.duration(EndDate.diff(StartDate));
        let hours = parseInt(duration.asHours());
        let minutes = parseInt(duration.asMinutes()) % 60;

        return (
          (hours > 0 ? this.lodash.padStart(hours, 2, "0") : "00") +
          ":" +
          (minutes > 0 ? this.lodash.padStart(minutes, 2, "0") : "00")
        );
      }
      return result;
    },
  },
  mounted() {
    const _this = this;
    _this.data = new Object({
      details: null,
      start_at: moment().startOf("day").format("hh:mm A"),
      end_at: moment().endOf("day").format("hh:mm A"),
      end_date: new Date().toISOString().substr(0, 10),
      date: new Date().toISOString().substr(0, 10),
      visit: _this.lodash.toSafeInteger(_this.visit.id),
    });
    if (_this.lodash.isEmpty(_this.update) === false) {
      _this.data.details = _this.update.details;
      _this.data.start_at = moment(_this.update.start_at).format("hh:mm A");
      _this.data.end_at = moment(_this.update.end_at).format("hh:mm A");
      _this.data.date = _this.update.date;
      _this.data.end_date = _this.update.end_date;
    }
  },
  components: {
    Dialog,
  },
};
