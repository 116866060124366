<template>
  <v-container
    fluid
    class="white-background client-service-forms service-report-form"
  >
    <v-row>
      <v-col class="py-0" md="6">
        <table class="width-100 table-bordered">
          <tbody>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Maintenance Site
              </td>
              <td>
                <v-text-field
                  v-model="formData.maintenance_site"
                  dense
                  filled
                  label="Maintenance Site"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Site Location
              </td>
              <td>
                <v-text-field
                  v-model="formData.site_location"
                  dense
                  filled
                  label="Site Location"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Site Address
              </td>
              <td>
                <v-text-field
                  v-model="formData.site_address"
                  dense
                  filled
                  label="Site Address"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Contact Name
              </td>
              <td>
                <v-text-field
                  v-model="formData.contact_name"
                  dense
                  filled
                  label="Contact Name"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Contact No
              </td>
              <td>
                <v-text-field
                  v-model="formData.contact_no"
                  dense
                  filled
                  label="Contact No"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col class="py-0" md="6">
        <table class="width-100 table-bordered">
          <tbody>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">Date</td>
              <td>
                <DatePicker v-model="formData.date" solo></DatePicker>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Maintenance Plan
              </td>
              <td>
                <v-text-field
                  v-model="formData.maintenance_plan"
                  dense
                  filled
                  label="Maintenance Plan"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Equipment ID
              </td>
              <td>
                <v-text-field
                  v-model="formData.equipment_id"
                  dense
                  filled
                  label="Equipment ID"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Equipment Model
              </td>
              <td>
                <v-text-field
                  v-model="formData.equipment_model"
                  dense
                  filled
                  label="Equipment Model"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Serial No
              </td>
              <td>
                <v-text-field
                  v-model="formData.serial_no"
                  dense
                  filled
                  label="Serial No"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col class="py-0" md="6">
        <table class="width-100 table-bordered my-4 mt-8">
          <tbody>
            <tr class="light-gray-background">
              <td class="font-weight-500 font-size-16 p-2">
                1a) Item v Normal / x Follow-up require
              </td>
              <td class="font-weight-500 font-size-16 p-2">Result</td>
            </tr>
            <tr v-for="(row, index) in formData.item1a" :key="index">
              <td class="font-weight-500 font-size-16 p-2">
                {{ row.item }}
              </td>
              <td>
                <v-checkbox
                  dense
                  v-model="row.result"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  color="cyan"
                  hide-details
                  class="m-0 mx-4"
                ></v-checkbox>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col class="py-0" md="6">
        <table class="width-100 table-bordered my-4 mt-8">
          <tbody>
            <tr class="light-gray-background">
              <td class="font-weight-500 font-size-16 p-2">
                1b) Item v Normal / x Follow-up require
              </td>
              <td class="font-weight-500 font-size-16 p-2">Result</td>
            </tr>
            <tr v-for="(row, index) in formData.item1b" :key="index">
              <td class="font-weight-500 font-size-16 p-2">
                {{ row.item }}
              </td>
              <td>
                <v-checkbox
                  dense
                  v-model="row.result"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  color="cyan"
                  hide-details
                  class="m-0 mx-4"
                ></v-checkbox>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col class="py-0" md="12">
        <p class="font-weight-500 font-size-16 m-0">
          2) Record unit temperature &amp; humility
        </p>
      </v-col>
      <v-col class="py-0" md="6">
        <table class="width-100 table-bordered my-4">
          <tbody>
            <tr>
              <td class="font-weight-500 font-size-16 p-2">
                Temperature set point (˚C )
              </td>
              <td>
                <v-text-field
                  v-model="
                    formData.record_unit_temperature_humility
                      .temperature_set_point
                  "
                  dense
                  filled
                  label="Temperature set point (˚C )"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2">
                Humility set point ( % )
              </td>
              <td>
                <v-text-field
                  v-model="
                    formData.record_unit_temperature_humility.humility_set_point
                  "
                  dense
                  filled
                  label="Humility set point ( % )"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col class="py-0" md="6">
        <table class="width-100 table-bordered my-4">
          <tbody>
            <tr>
              <td class="font-weight-500 font-size-16 p-2">
                Return air temperature (˚C )
              </td>
              <td>
                <v-text-field
                  v-model="
                    formData.record_unit_temperature_humility
                      .return_air_temperature
                  "
                  dense
                  filled
                  label="Return air temperature (˚C )"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2">
                Return air humility ( % )
              </td>
              <td>
                <v-text-field
                  v-model="
                    formData.record_unit_temperature_humility
                      .return_air_humility
                  "
                  dense
                  filled
                  label="Return air humility ( % )"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col class="py-0" md="12">
        <p class="font-weight-500 font-size-16 m-0">
          3) Record component amperes
        </p>
      </v-col>
      <v-col class="py-0" md="6">
        <table class="width-100 table-bordered mt-4">
          <tbody>
            <tr class="light-gray-background">
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Amperes ( A )
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center">
                L1
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center">
                L2
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center">
                L3
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Blower Fan 1
              </td>
              <td>
                <v-text-field
                  v-model="formData.record_component_amperes.blower_fan_1.l1"
                  dense
                  filled
                  label="L1"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="formData.record_component_amperes.blower_fan_1.l2"
                  dense
                  filled
                  label="L2"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="formData.record_component_amperes.blower_fan_1.l3"
                  dense
                  filled
                  label="L3"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Blower Fan 2
              </td>
              <td>
                <v-text-field
                  v-model="formData.record_component_amperes.blower_fan_2.l1"
                  dense
                  filled
                  label="L1"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="formData.record_component_amperes.blower_fan_2.l2"
                  dense
                  filled
                  label="L2"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="formData.record_component_amperes.blower_fan_2.l3"
                  dense
                  filled
                  label="L3"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Blower Fan 3
              </td>
              <td>
                <v-text-field
                  v-model="formData.record_component_amperes.blower_fan_3.l1"
                  dense
                  filled
                  label="L1"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="formData.record_component_amperes.blower_fan_3.l2"
                  dense
                  filled
                  label="L2"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="formData.record_component_amperes.blower_fan_3.l3"
                  dense
                  filled
                  label="L3"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col class="py-0" md="6">
        <table class="width-100 table-bordered mt-4">
          <tbody>
            <tr class="light-gray-background">
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Amperes ( A )
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center">
                L1
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center">
                L2
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center">
                L3
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Heater 1
              </td>
              <td>
                <v-text-field
                  v-model="formData.record_component_amperes.heater_1.l1"
                  dense
                  filled
                  label="L1"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="formData.record_component_amperes.heater_1.l2"
                  dense
                  filled
                  label="L2"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="formData.record_component_amperes.heater_1.l3"
                  dense
                  filled
                  label="L3"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Heater 2
              </td>
              <td>
                <v-text-field
                  v-model="formData.record_component_amperes.heater_2.l1"
                  dense
                  filled
                  label="L1"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="formData.record_component_amperes.heater_2.l2"
                  dense
                  filled
                  label="L2"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="formData.record_component_amperes.heater_2.l3"
                  dense
                  filled
                  label="L3"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Heater 3
              </td>
              <td>
                <v-text-field
                  v-model="formData.record_component_amperes.heater_3.l1"
                  dense
                  filled
                  label="L1"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="formData.record_component_amperes.heater_3.l2"
                  dense
                  filled
                  label="L2"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="formData.record_component_amperes.heater_3.l3"
                  dense
                  filled
                  label="L3"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col class="py-0" md="6">
        <table class="width-100 table-bordered mt-8">
          <tbody>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Compressor 1
              </td>
              <td>
                <v-text-field
                  v-model="formData.record_component_amperes.compressor_1.l1"
                  dense
                  filled
                  label="L1"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="formData.record_component_amperes.compressor_1.l2"
                  dense
                  filled
                  label="L2"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="formData.record_component_amperes.compressor_1.l3"
                  dense
                  filled
                  label="L3"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Compressor 2
              </td>
              <td>
                <v-text-field
                  v-model="formData.record_component_amperes.compressor_2.l1"
                  dense
                  filled
                  label="L1"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="formData.record_component_amperes.compressor_2.l2"
                  dense
                  filled
                  label="L2"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="formData.record_component_amperes.compressor_2.l3"
                  dense
                  filled
                  label="L3"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col class="py-0" md="6">
        <table class="width-100 table-bordered mt-8">
          <tbody>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Condenser fan circuit 1
              </td>
              <td>
                <v-text-field
                  v-model="
                    formData.record_component_amperes.compressor_fan_circuit_1
                      .l1
                  "
                  dense
                  filled
                  label="L1"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="
                    formData.record_component_amperes.compressor_fan_circuit_1
                      .l2
                  "
                  dense
                  filled
                  label="L2"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="
                    formData.record_component_amperes.compressor_fan_circuit_1
                      .l3
                  "
                  dense
                  filled
                  label="L3"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Condenser fan circuit 2
              </td>
              <td>
                <v-text-field
                  v-model="
                    formData.record_component_amperes.compressor_fan_circuit_2
                      .l1
                  "
                  dense
                  filled
                  label="L1"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="
                    formData.record_component_amperes.compressor_fan_circuit_2
                      .l2
                  "
                  dense
                  filled
                  label="L2"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="
                    formData.record_component_amperes.compressor_fan_circuit_2
                      .l3
                  "
                  dense
                  filled
                  label="L3"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col class="py-0" md="12">
        <table class="width-100 table-bordered my-8">
          <tbody>
            <tr class="light-gray-background">
              <td class="font-weight-500 font-size-16 p-2">Remarks</td>
            </tr>
            <tr>
              <td>
                <v-textarea
                  v-model="formData.remarks"
                  auto-grow
                  dense
                  filled
                  color="cyan"
                  label="Remarks"
                  solo
                  flat
                  row-height="25"
                ></v-textarea>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="width-100 table-bordered">
          <tbody>
            <tr class="light-gray-background">
              <td class="font-weight-500 font-size-16 p-2">
                Comments and Recommendation
              </td>
            </tr>
            <tr>
              <td>
                <v-textarea
                  v-model="formData.comments_and_recommendation"
                  auto-grow
                  dense
                  filled
                  color="cyan"
                  label="Comments and Recommendation"
                  solo
                  flat
                  row-height="25"
                ></v-textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col v-if="false" class="py-0" md="6">
        <table class="width-100 mt-8">
          <tbody>
            <tr>
              <td height="100" colspan="2" id="engineer-signature">
                <div class="engineer-signature custom-border-grey-dashed">
                  <canvas ref="engineersignature"></canvas>
                </div>
                <p class="mt-2 mx-2 mb-0 text-right">
                  <v-btn
                    content="Click here to clear signature"
                    v-tippy="{ arrow: true, placement: 'top' }"
                    icon
                    small
                    class="float-left"
                    v-on:click="engineerSignature.clear()"
                  >
                    <v-icon>mdi-close-circle-outline</v-icon>
                  </v-btn>
                  Service Engineer Name/Signature
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col v-if="false" class="py-0" md="6">
        <table class="width-100 mt-8">
          <tbody>
            <tr>
              <td height="100" colspan="2" id="customer-signature">
                <div class="customer-signature custom-border-grey-dashed">
                  <canvas ref="customersignature"></canvas>
                </div>
                <p class="mt-2 mx-2 mb-0 text-right">
                  <v-btn
                    content="Click here to clear signature"
                    v-tippy="{ arrow: true, placement: 'top' }"
                    icon
                    small
                    class="float-left"
                    v-on:click="customerSignature.clear()"
                  >
                    <v-icon>mdi-close-circle-outline</v-icon>
                  </v-btn>
                  Customer Name/Signature
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DatePicker from "@/view/pages/partials/Datepicker";
/*import SignaturePad from "signature_pad";*/
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";
import { PATCH } from "@/core/services/store/request.module";

export default {
  props: {
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    serviceForm: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    contract: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    customer: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    property: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    billing: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    contactPerson: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  data: () => {
    return {
      pageLoading: true,
      engineerSignature: new Object(),
      customerSignature: new Object(),
      formData: new Object({
        barcode: null,
        maintenance_site: null,
        site_location: null,
        site_address: null,
        contact_name: null,
        contact_no: null,
        date: null,
        maintenance_plan: null,
        equipment_id: null,
        equipment_model: null,
        serial_no: null,
        remarks: null,
        comments_and_recommendation: null,
        customer_signature: null,
        engineer_signature: null,
        item1a: [
          {
            item: "Check unit operate in unusual noise",
            result: false,
          },
          {
            item: "Check fans blades condition",
            result: false,
          },
          {
            item: "Check fans belts condition",
            result: false,
          },
          {
            item: "Check air filters condition",
            result: false,
          },
          {
            item: "Check heaters condition",
            result: false,
          },
          {
            item: "Check compressors condition",
            result: false,
          },
          {
            item: "Check liquid sight glass",
            result: false,
          },
        ],
        item1b: [
          {
            item: "Check condensate tray",
            result: false,
          },
          {
            item: "Check actuator operation",
            result: false,
          },
          {
            item: "Check unit LCD display",
            result: false,
          },
          {
            item: "Check wire connection tightness",
            result: false,
          },
          {
            item: "Check condensate pump",
            result: false,
          },
          {
            item: "Check condenser condition",
            result: false,
          },
          {
            item: "Check humidifier condition",
            result: false,
          },
        ],
        record_unit_temperature_humility: {
          temperature_set_point: null,
          humility_set_point: null,
          return_air_temperature: null,
          return_air_humility: null,
        },
        record_component_amperes: {
          blower_fan_1: {
            l1: null,
            l2: null,
            l3: null,
          },
          blower_fan_2: {
            l1: null,
            l2: null,
            l3: null,
          },
          blower_fan_3: {
            l1: null,
            l2: null,
            l3: null,
          },
          heater_1: {
            l1: null,
            l2: null,
            l3: null,
          },
          heater_2: {
            l1: null,
            l2: null,
            l3: null,
          },
          heater_3: {
            l1: null,
            l2: null,
            l3: null,
          },
          compressor_1: {
            l1: null,
            l2: null,
            l3: null,
          },
          compressor_2: {
            l1: null,
            l2: null,
            l3: null,
          },
          compressor_fan_circuit_1: {
            l1: null,
            l2: null,
            l3: null,
          },
          compressor_fan_circuit_2: {
            l1: null,
            l2: null,
            l3: null,
          },
        },
      }),
    };
  },
  components: {
    DatePicker,
  },
  methods: {
    updatePreInformation() {
      const _this = this;
      try {
        if (_this.lodash.isEmpty(_this.customer) === false) {
          _this.formData.customer_name = _this.customer.company_name;
        }
        if (_this.lodash.isEmpty(_this.property) === false) {
          _this.formData.site_address =
            _this.property.unit_no + " " + _this.property.street_1;
          _this.formData.site_location =
            _this.property.street_2 + " " + _this.property.zip_code;
        }
        if (_this.lodash.isEmpty(_this.detail) === false) {
          _this.formData.contact_name = _this.detail.attention;
        }
        if (_this.lodash.isEmpty(_this.contactPerson) === false) {
          _this.formData.contact_no = _this.contactPerson.primary_phone;
        }
        if (_this.lodash.isEmpty(_this.serviceForm) === false) {
          _this.formData.barcode = _this.serviceForm.barcode;
          if (_this.lodash.isEmpty(_this.serviceForm.attributes) === false) {
            _this.formData = _this.serviceForm.attributes;

            /*if (
              _this.formData.engineer_signature
            ) {
              _this.engineerSignature.fromDataURL(
                _this.formData.engineer_signature
              );
            }
            if (
              _this.formData.customer_signature
            ) {
              _this.customerSignature.fromDataURL(
                _this.formData.customer_signature
              );
            }*/
          }
        }
        _this.pageLoading = false;
      } catch (error) {
        _this.pageLoading = false;
      }
    },
    saveServiceForm(action, product) {
      const _this = this;

      ServiceFormEventBus.$emit("start:loader");

      let param = _this.formData;

      /*if (_this.engineerSignature) {
        param.engineer_signature = _this.engineerSignature.toDataURL();
      }
      if (_this.customerSignature) {
        param.customer_signature = _this.customerSignature.toDataURL();
      }*/

      _this.$store
        .dispatch(PATCH, {
          url:
            "job/" +
            _this.detail.id +
            "/visit/" +
            _this.serviceForm.visit.id +
            "/service-form/" +
            _this.serviceForm.id,
          data: { attributes: param, action, product },
        })
        .then(({ data }) => {
          ServiceFormEventBus.$emit("save:service-form", data);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          ServiceFormEventBus.$emit("close:loader");
        });
    },
  },
  beforeDestroy() {
    ServiceFormEventBus.$off("start:save-service-form");
    ServiceFormEventBus.$off("update:service-form-product");
  },
  mounted() {
    const _this = this;

    ServiceFormEventBus.$on("start:save-service-form", (param, product) => {
      _this.saveServiceForm(param, product);
    });

    ServiceFormEventBus.$on("update:service-form-product", (param) => {
      _this.formData.maintenance_plan = _this.contract
        ? _this.contract.contract_type
        : null;
      _this.formData.equipment_id = param ? param.name : null;
      _this.formData.equipment_model = param ? param.brand : null;
    });

    _this.updatePreInformation();

    setTimeout(function () {
      _this.$nextTick(() => {
        /*let ecanvas = _this.$refs["engineersignature"];
        let eparentDiv = document.getElementById("engineer-signature");
        let eparentWidth = eparentDiv.offsetWidth;
        ecanvas.setAttribute("width", eparentWidth);
        _this.engineerSignature = new SignaturePad(ecanvas);

        let ccanvas = _this.$refs["customersignature"];
        let cparentDiv = document.getElementById("customer-signature");
        let cparentWidth = cparentDiv.offsetWidth;
        ccanvas.setAttribute("width", cparentWidth);
        _this.customerSignature = new SignaturePad(ccanvas);*/
      });
    }, 1000);
  },
};
</script>
