<template>
  <transition name="fade-in-up">
    <v-container
      fluid
      class="service-form-template custom-border-grey-dashed"
      id="service-form"
      :class="{
        'pointer-events-none': !getPermission('service-form:update'),
      }"
    >
      <!-- <Header></Header> -->
      <component
        :is="getComponent"
        :detail.sync="detail"
        :serviceForm.sync="serviceForm"
        :customer.sync="customer"
        :property.sync="property"
        :contract.sync="contract"
        :billing.sync="billing"
        :contactPerson.sync="contactPerson"
      ></component>
      <!-- <Footer></Footer> -->
    </v-container>
  </transition>
</template>

<script>
/*import Footer from '@/view/pages/service-form/Footer.vue';*/
/*import Header from "@/view/pages/service-form/Header.vue";*/
import ABBServiceForm from "@/view/pages/service-form/soled-engineering/ABB.vue";
import BATT_S1ServiceForm from "@/view/pages/service-form/soled-engineering/BATT_S1.vue";
import CRAUServiceForm from "@/view/pages/service-form/soled-engineering/CRAU.vue";
import EXLServiceForm from "@/view/pages/service-form/soled-engineering/EXL.vue";
import HWServiceForm from "@/view/pages/service-form/soled-engineering/HW.vue";
import LiebertServiceForm from "@/view/pages/service-form/soled-engineering/Liebert.vue";
import ServiceReportServiceForm from "@/view/pages/service-form/soled-engineering/Service-Report.vue";

export default {
  components: {
    /*Footer,*/
    /*Header,*/
    ABBServiceForm,
    BATT_S1ServiceForm,
    CRAUServiceForm,
    EXLServiceForm,
    HWServiceForm,
    LiebertServiceForm,
    ServiceReportServiceForm,
  },
  props: {
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    serviceForm: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    customer: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    contract: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    property: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    billing: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    contactPerson: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  computed: {
    getComponent() {
      let component = null;
      if (this.lodash.isEmpty(this.serviceForm) === false) {
        switch (this.lodash.toLower(this.serviceForm.service_form)) {
          case "abb": {
            component = ABBServiceForm;
            break;
          }
          case "batt_s1": {
            component = BATT_S1ServiceForm;
            break;
          }
          case "crau": {
            component = CRAUServiceForm;
            break;
          }
          case "exl": {
            component = EXLServiceForm;
            break;
          }
          case "hw": {
            component = HWServiceForm;
            break;
          }
          case "liebert": {
            component = LiebertServiceForm;
            break;
          }
          case "service-report": {
            component = ServiceReportServiceForm;
            break;
          }
        }
      }
      return component;
    },
  },
};
</script>
