import CommonMixin from "@/core/plugins/common-mixin";
import { POST, GET, UPLOAD } from "@/core/services/store/request.module";
import Dialog from "@/view/pages/partials/Dialog.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import SignaturePad from "signature_pad";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";

export default {
  mixins: [CommonMixin, ValidationMixin, FileManagerMixin],
  props: {
    closerDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    timesheet: {
      type: Number,
      default: 0,
    },
    visit: {
      type: Number,
      default: 0,
    },
    visitDetail: {
      type: Object,
      default() {
        return new Object();
      },
    },
    isVisitDetail: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pageLoading: false,
      recordingStarted: false,
      recordingStopped: false,
      engineerSignature: null,
      customerSignature: null,
      audioDataURL: null,
      disableVisit: false,
      timeTrackings: [],
      visitList: [],
      data: {
        timesheet: null,
        attachments: [],
        visit: 0,
        details: null,
      },
    };
  },
  watch: {
    "data.visit"() {
      this.getTimeTracking();
    },
    visitDetail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.data.visit = this.lodash.toSafeInteger(param.id);
      },
    },
  },
  methods: {
    updateFiles(param) {
      this.data.attachments = param;
    },
    recordingStartedUpdate(param) {
      this.recordingStarted = param;
    },
    recordingStoppedUpdate(param) {
      this.recordingStopped = param;
    },
    updateOrCreate(action) {
      const _this = this;

      if (!_this.$refs.ticketCloserForm.validate()) {
        return false;
      }

      if (_this.recordingStarted === true && _this.recordingStopped === false) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please stop the audio recorder then try again.")
        );
        return false;
      }

      _this.formLoading = true;

      const signatures = [];
      signatures.push({ engineer: this.engineerSignature.toDataURL() });
      signatures.push({ customer: this.customerSignature.toDataURL() });

      const formData = {
        action,
        ticket: _this.detail.id,
        visit: _this.data.visit,
        timesheet: _this.data.timesheet,
        attachments: _this.data.attachments,
        signatures: signatures,
        audio: _this.audioDataURL,
        details: _this.data.details,
      };
      _this.$store
        .dispatch(POST, {
          url:
            "job/" + _this.detail.id + "/visit/" + _this.data.visit + "/closer",
          data: formData,
        })
        .then(() => {
          _this.$emit("create:ticket-closer", true);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    getTimeTracking() {
      const _this = this;
      let url = "job/" + _this.detail.id + "/time-tracking";
      if (_this.data.visit) {
        url =
          "job/" +
          _this.detail.id +
          "/visit/" +
          _this.data.visit +
          "/time-tracking";
      }
      _this.$store
        .dispatch(GET, { url })
        .then(({ data }) => {
          if (_this.lodash.isArray(data)) {
            _this.timeTrackings = _this.lodash.compact(
              data.map((row) => {
                return _this.lodash.isEmpty(row.closer) ? row : undefined;
              })
            );
          }
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getJobVisits() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "job/" + _this.detail.id + "/visit",
        })
        .then(({ data }) => {
          if (_this.lodash.isArray(data)) {
            _this.visitList = data.map((row) => {
              if (row.header) {
                return { header: row.header_text };
              }
              return row;
            });
          }
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    engineerDisplayText(param) {
      const _this = this;
      let display_name = new Array();
      if (
        _this.lodash.isEmpty(param) === false &&
        _this.lodash.isArray(param)
      ) {
        for (let i = 0; i < param.length; i++) {
          if (_this.lodash.isEmpty(param[i].engineer) === false) {
            display_name.push(param[i].engineer.display_name);
          }
        }
      }
      if (_this.lodash.isEmpty(display_name)) {
        return "Not assigned yet.";
      } else {
        return "Assigned to " + display_name.join(", ");
      }
    },
    ClickInputField(param) {
      const _this = this;
      if (param) {
        let formData = new FormData();
        formData.append("documents[0]", param, param.name);
        _this.$store
          .dispatch(UPLOAD, { url: "file-manager/documents", data: formData })
          .then(({ data }) => {
            _this.data.attachments.push(data.documents[0]);
          })
          .catch((error) => {
            _this.logError(error);
          });
      }
    },
  },
  components: {
    Dialog,
  },
  mounted() {
    const _this = this;
    _this.getJobVisits();
    _this.getTimeTracking();
    _this.data.timesheet = 0;
    if (_this.timesheet > 0) {
      _this.data.timesheet = _this.timesheet;
    }

    if (_this.visit > 0) {
      _this.disableVisit = true;
      _this.data.visit = _this.visit;
    }

    setTimeout(function () {
      _this.$nextTick(() => {
        let ecanvas = _this.$refs["engineersignature"];
        let eparentDiv = document.getElementById("engineer-signature");
        let eparentWidth = eparentDiv.offsetWidth;
        ecanvas.setAttribute("width", eparentWidth);
        _this.engineerSignature = new SignaturePad(ecanvas);

        let ccanvas = _this.$refs["customersignature"];
        let cparentDiv = document.getElementById("customer-signature");
        let cparentWidth = cparentDiv.offsetWidth;
        ccanvas.setAttribute("width", cparentWidth);
        _this.customerSignature = new SignaturePad(ccanvas);
      });
    }, 1000);
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
};
