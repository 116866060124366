<template>
  <v-container
    fluid
    class="white-background client-service-forms service-report-form"
  >
    <v-row>
      <v-col md="6">
        <table class="width-100 table-bordered">
          <tbody>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Customer Name
              </td>
              <td>
                <v-text-field
                  v-model="formData.customer_name"
                  dense
                  filled
                  label="Customer Name"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Site Location
              </td>
              <td>
                <v-text-field
                  v-model="formData.site_location"
                  dense
                  filled
                  label="Site Location"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Site Address
              </td>
              <td>
                <v-text-field
                  v-model="formData.site_address"
                  dense
                  filled
                  label="Site Address"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Contact Name
              </td>
              <td>
                <v-text-field
                  v-model="formData.contact_name"
                  dense
                  filled
                  label="Contact Name"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Contact No
              </td>
              <td>
                <v-text-field
                  v-model="formData.contact_no"
                  dense
                  filled
                  label="Contact No"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col md="6">
        <table class="width-100 table-bordered">
          <tbody>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">Date</td>
              <td colspan="2">
                <DatePicker v-model="formData.date" solo></DatePicker>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Maintenance Plan
              </td>
              <td colspan="2">
                <v-text-field
                  v-model="formData.maintenance_plan"
                  dense
                  filled
                  label="Maintenance Plan"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Equipment ID
              </td>
              <td colspan="2">
                <v-text-field
                  v-model="formData.equipment_id"
                  dense
                  filled
                  label="Equipment"
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Battery Model
              </td>
              <td>
                <v-text-field
                  v-model="formData.battery_model.one"
                  dense
                  filled
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="formData.battery_model.two"
                  dense
                  filled
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Location
              </td>
              <td>
                <v-text-field
                  v-model="formData.location.one"
                  dense
                  filled
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="formData.location.two"
                  dense
                  filled
                  hide-details
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col md="6">
        <table class="width-100 table-bordered">
          <tbody>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" align="center">
                ID
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center">
                S1 Impedance (mΩ)
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center">
                S1 Voltage (Vdc)
              </td>
            </tr>
            <tr class="light-gray-background">
              <td class="font-weight-500 font-size-16 p-2" align="center">
                Ref
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center">
                11.5
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center">
                13.5
              </td>
            </tr>
            <template v-for="index in 50">
              <tr v-if="formData.checklist[index - 1]" :key="index">
                <td class="font-weight-500 font-size-16 p-2" align="center">
                  {{ index }}
                </td>
                <td>
                  <v-text-field
                    v-model.number="formData.checklist[index - 1].s1_impedance"
                    dense
                    filled
                    type="number"
                    step="0.01"
                    hide-details
                    solo
                    flat
                    color="cyan"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model.number="formData.checklist[index - 1].s1_voltage"
                    dense
                    filled
                    type="number"
                    step="0.01"
                    hide-details
                    solo
                    flat
                    color="cyan"
                  ></v-text-field>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </v-col>
      <v-col md="6">
        <table class="width-100 table-bordered">
          <tbody>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" align="center">
                S1 Temperature (°C)
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center">
                Date / Time
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center">
                Remarks
              </td>
            </tr>
            <tr class="light-gray-background">
              <td class="font-weight-500 font-size-16 p-2" align="center">
                25
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center"></td>
              <td class="font-weight-500 font-size-16 p-2" align="center"></td>
            </tr>
            <template v-for="index in 50">
              <tr v-if="formData.checklist[index - 1]" :key="index">
                <td>
                  <v-text-field
                    v-model.number="
                      formData.checklist[index - 1].s1_temperature
                    "
                    dense
                    filled
                    hide-details
                    solo
                    type="number"
                    step="0.01"
                    flat
                    color="cyan"
                  ></v-text-field>
                </td>
                <td>
                  <DateTimePicker
                    v-model="formData.checklist[index - 1].date_time"
                  ></DateTimePicker>
                </td>
                <td>
                  <v-text-field
                    v-model="formData.checklist[index - 1].remarks"
                    dense
                    filled
                    hide-details
                    solo
                    flat
                    color="cyan"
                  ></v-text-field>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </v-col>
      <v-col v-if="false" md="6">
        <table class="width-100 table-bordered">
          <tbody>
            <tr>
              <td height="100" colspan="2" id="engineer-signature">
                <div class="engineer-signature custom-border-grey-dashed">
                  <canvas ref="engineersignature"></canvas>
                </div>
                <p class="mt-2 mx-2 mb-0 text-right">
                  <v-btn
                    content="Click here to clear signature"
                    v-tippy="{ arrow: true, placement: 'top' }"
                    icon
                    small
                    class="float-left"
                    v-on:click="engineerSignature.clear()"
                  >
                    <v-icon>mdi-close-circle-outline</v-icon>
                  </v-btn>
                  Service Engineer Name/Signature
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col v-if="false" md="6">
        <table class="width-100 table-bordered">
          <tbody>
            <tr>
              <td height="100" colspan="2" id="customer-signature">
                <div class="customer-signature custom-border-grey-dashed">
                  <canvas ref="customersignature"></canvas>
                </div>
                <p class="mt-2 mx-2 mb-0 text-right">
                  <v-btn
                    content="Click here to clear signature"
                    v-tippy="{ arrow: true, placement: 'top' }"
                    icon
                    small
                    class="float-left"
                    v-on:click="customerSignature.clear()"
                  >
                    <v-icon>mdi-close-circle-outline</v-icon>
                  </v-btn>
                  Customer Name/Signature
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DatePicker from "@/view/pages/partials/Datepicker";
import DateTimePicker from "@/view/pages/partials/DateTimePicker";
/*import SignaturePad from "signature_pad";*/
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";
import { PATCH } from "@/core/services/store/request.module";
import ObjectPath from "object-path";

let checklistItems = new Array();

for (let i = 1; i <= 50; i++) {
  checklistItems[i] = new Object({
    s1_impedance: null,
    s1_voltage: null,
    s1_temperature: null,
    date_time: null,
    remarks: null,
  });
}

export default {
  props: {
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    serviceForm: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    contract: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    customer: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    property: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    billing: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    contactPerson: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  data: () => {
    return {
      pageLoading: true,
      engineerSignature: new Object(),
      customerSignature: new Object(),
      formData: new Object({
        barcode: null,
        customer_name: null,
        site_location: null,
        site_address: null,
        contact_name: null,
        contact_no: null,
        date: null,
        maintenance_plan: null,
        equipment_id: null,
        battery_model: {
          one: null,
          two: null,
        },
        location: {
          one: null,
          two: null,
        },
        customer_signature: null,
        engineer_signature: null,
        checklist: checklistItems,
      }),
    };
  },
  components: {
    DatePicker,
    DateTimePicker,
  },
  methods: {
    updatePreInformation() {
      const _this = this;

      try {
        _this.formData = ObjectPath.get(_this.serviceForm, "attributes");

        _this.formData.barcode = ObjectPath.get(_this.serviceForm, "barcode");

        _this.pageLoading = false;
      } catch (error) {
        _this.pageLoading = false;
      }
    },
    saveServiceForm(action, product) {
      const _this = this;

      ServiceFormEventBus.$emit("start:loader");

      let param = _this.formData;

      /*if (_this.engineerSignature) {
        param.engineer_signature = _this.engineerSignature.toDataURL();
      }
      if (_this.customerSignature) {
        param.customer_signature = _this.customerSignature.toDataURL();
      }*/

      _this.$store
        .dispatch(PATCH, {
          url:
            "job/" +
            _this.detail.id +
            "/visit/" +
            _this.serviceForm.visit.id +
            "/service-form/" +
            _this.serviceForm.id,
          data: { attributes: param, action, product },
        })
        .then(({ data }) => {
          ServiceFormEventBus.$emit("save:service-form", data);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          ServiceFormEventBus.$emit("close:loader");
        });
    },
  },
  beforeDestroy() {
    ServiceFormEventBus.$off("start:save-service-form");
    ServiceFormEventBus.$off("update:service-form-product");
  },
  mounted() {
    const _this = this;

    ServiceFormEventBus.$on("start:save-service-form", (param, product) => {
      _this.saveServiceForm(param, product);
    });

    ServiceFormEventBus.$on("update:service-form-product", (param) => {
      _this.formData.maintenance_plan = ObjectPath.get(
        _this.contract,
        "contract_type"
      );
      _this.formData.equipment_id = ObjectPath.get(param, "name");
      _this.formData.battery_model.one = ObjectPath.get(param, "battery_model");
    });

    _this.updatePreInformation();

    setTimeout(function () {
      _this.$nextTick(() => {
        /*let ecanvas = _this.$refs["engineersignature"];
        let eparentDiv = document.getElementById("engineer-signature");
        let eparentWidth = eparentDiv.offsetWidth;
        ecanvas.setAttribute("width", eparentWidth);
        _this.engineerSignature = new SignaturePad(ecanvas);

        let ccanvas = _this.$refs["customersignature"];
        let cparentDiv = document.getElementById("customer-signature");
        let cparentWidth = cparentDiv.offsetWidth;
        ccanvas.setAttribute("width", cparentWidth);
        _this.customerSignature = new SignaturePad(ccanvas);*/
      });
    }, 1000);
  },
};
</script>
