<template>
  <Dialog :commonDialog.sync="closerDialog" :dialogWidth="dialogWidth">
    <template v-slot:title>
      Closer
      <v-icon small class="mx-4 color-custom-blue"
        >mdi-checkbox-blank-circle</v-icon
      >
      <template v-if="lodash.isEmpty(visitDetail) === false"
        >Visit# {{ visitDetail.barcode }}</template
      >
      <template v-else>Job# {{ detail.barcode }}</template>
    </template>
    <template v-slot:body>
      <!-- <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow"
        style="max-height: 60vh; position: relative"
      > -->
      <v-form
        ref="ticketCloserForm"
        v-model="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate('')"
      >
        <v-container class="py-0">
          <v-row>
            <v-col md="12" v-if="!isVisitDetail">
              <p class="font-weight-500">Visit</p>
              <v-autocomplete
                v-model="data.visit"
                :rules="[validateRules.required(data.visit, 'Visit')]"
                :items="visitList"
                hide-details
                dense
                filled
                flat
                label="Visit"
                item-color="cyan"
                color="cyan"
                solo
                :disabled="disableVisit"
                item-text="barcode"
                item-value="id"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title
                      v-html="'No Visit(s) Found.'"
                    ></v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <p class="m-0 font-size-16 text-ellipsis text-capitalize">
                    {{ item.barcode }} ({{ formatDateTime(item.started_at) }} -
                    {{ formatTime(item.finished_at) }})
                  </p>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content :class="[item.header_text]">
                    <v-list-item-title
                      class="font-size-16 pl-2 text-ellipsis pb-1 text-capitalize"
                      :style="{ color: item.header_color }"
                      >{{ item.barcode }} ({{
                        formatDateTime(item.started_at)
                      }}
                      - {{ formatTime(item.finished_at) }})</v-list-item-title
                    >
                    <v-list-item-subtitle
                      class="font-size-14 pl-2 text-ellipsis pb-1"
                      :style="{ color: item.header_color }"
                      >{{
                        engineerDisplayText(item.engineers)
                      }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col md="12" v-if="!timesheet">
              <p class="font-weight-500">Timesheet</p>
              <v-autocomplete
                hide-details
                :rules="[validateRules.required(data.timesheet, 'Timesheet')]"
                v-model="data.timesheet"
                :items="timeTrackings"
                dense
                flat
                filled
                label="Timesheet"
                item-color="cyan"
                color="cyan"
                solo
                item-text="barcode"
                item-value="id"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title
                      v-html="'No Timesheet(s) Found.'"
                    ></v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-avatar v-if="item.engineer">
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-img
                          v-bind="attrs"
                          v-on="on"
                          :lazy-src="$defaultProfileImage"
                          :src="$assetAPIURL(item.engineer.profile_logo)"
                          aspect-ratio="1"
                          class="margin-auto white--background"
                          transition="fade-transition"
                        ></v-img>
                      </template>
                      <span>{{ item.engineer.display_name }}</span>
                    </v-tooltip>
                  </v-list-item-avatar>
                  <v-list-item-content class="max-width-500px">
                    <v-list-item-title
                      class="font-size-16 pl-2 text-ellipsis pb-1 text-capitalize"
                      >{{ item.barcode }} ({{ formatDateTime(item.start_at) }} -
                      {{ formatTime(item.end_at) }})
                      <v-chip
                        v-if="item.next_day"
                        class="mx-2 font-weight-500 text-uppercase"
                        label
                        small
                      >
                        Next Day
                      </v-chip>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      v-if="item.visit"
                      class="font-size-14 pl-2 text-ellipsis pb-1"
                      >{{ item.visit.barcode }} ({{
                        formatDateTime(item.visit.started_at)
                      }}
                      -
                      {{
                        formatTime(item.visit.finished_at)
                      }})</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col md="12">
              <p class="font-weight-500">Details</p>
              <v-textarea
                v-model="data.details"
                :rules="[validateRules.required(data.details, 'Details')]"
                auto-grow
                dense
                filled
                flat
                label="Details"
                solo
                color="cyan"
                hide-details
                row-height="20"
              ></v-textarea>
            </v-col>
            <v-col md="12" v-if="$haveRecordAudio">
              <!-- Disabled for SOled Engineering Solutions Pte Ltd -->
              <p class="font-weight-500">Record Audio</p>
              <RecordAudio
                v-model="audioDataURL"
                v-on:start-recording="recordingStartedUpdate"
                v-on:stop-recording="recordingStoppedUpdate"
              ></RecordAudio>
            </v-col>
            <v-col md="12">
              <p class="font-weight-500">Attachments</p>
              <FileTemplate
                allowUpload
                isMinDisplay
                v-on:file:updated="updateFiles"
              ></FileTemplate>
            </v-col>
            <v-col md="12">
              <v-row>
                <v-col md="6" class="custom-border-right">
                  <p class="font-weight-500">
                    Engineer's Signature
                    <v-btn
                      content="Click here to clear signature"
                      v-tippy="{ arrow: true, placement: 'top' }"
                      icon
                      small
                      class="float-right"
                      v-on:click="engineerSignature.clear()"
                    >
                      <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </p>
                  <div
                    id="engineer-signature"
                    class="engineer-signature custom-border-grey-dashed"
                  >
                    <canvas ref="engineersignature"></canvas>
                  </div>
                </v-col>
                <v-col md="6">
                  <p class="font-weight-500">
                    Customer's Signature
                    <v-btn
                      content="Click here to clear signature"
                      v-tippy="{ arrow: true, placement: 'top' }"
                      icon
                      small
                      class="float-right"
                      v-on:click="customerSignature.clear()"
                    >
                      <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </p>
                  <div
                    id="customer-signature"
                    class="customer-signature custom-border-grey-dashed"
                  >
                    <canvas ref="customersignature"></canvas>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <!-- </perfect-scrollbar> -->
    </template>
    <template v-slot:action>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-grey-border custom-bold-button white--text"
        color="cyan"
        v-on:click="updateOrCreate('mark_as_complete')"
      >
        Mark As Complete
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-grey-border custom-bold-button white--text"
        color="cyan"
        v-on:click="updateOrCreate('stop_timer')"
      >
        Hold and Stop Timer
      </v-btn>
      <template v-if="false">
        <v-menu
          transition="slide-y-transition"
          top
          content-class="custom-menu-list"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="!formValid || formLoading || pageLoading"
              :loading="formLoading"
              class="mx-2 custom-grey-border custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
            >
              Hold and... <v-icon right>mdi-chevron-up</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  link
                  v-on:click="updateOrCreate('stop_timer')"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                    <v-icon class="icon-default-blue"
                      >mdi-clock-check-outline</v-icon
                    >
                  </v-list-item-icon>
                  <v-list-item-title class="font-weight-500 font-size-14"
                    >Stop Timer</v-list-item-title
                  >
                </v-list-item>
              </template>
              <span>Hold and Stop Timer</span>
            </v-tooltip>
          </v-list>
        </v-menu>
      </template>
      <v-btn
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="$emit('close-dialog', true)"
      >
        Close
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import CreateTicketCloserMixin from "@/core/lib/ticket-closer/create.ticket.closer.mixin";
import FileTemplate from "@/view/pages/partials/FileTemplate";
import RecordAudio from "@/view/pages/partials/RecordAudio";

export default {
  mixins: [CreateTicketCloserMixin],
  components: {
    FileTemplate,
    RecordAudio,
  },
};
</script>
